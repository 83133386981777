import { FC } from "react";
import { To } from "react-router-dom";

import { MessageBar, MessageBarType, Stack } from "@bps/fluent-ui";

import { Navigate } from "./navigation/Navigate.tsx";

type ErrorBlockProps = {
  errorText: string;
  linkText?: string;
  to?: To;
  errorType?: MessageBarType;
  onClick?: () => void;
};

export const ErrorBlock: FC<ErrorBlockProps> = ({
  errorText,
  onClick,
  linkText,
  to,
  errorType
}) => {
  return (
    <MessageBar
      messageBarIconProps={{ iconName: "Blocked2" }}
      messageBarType={errorType ?? MessageBarType.error}
      styles={{
        innerText: {
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          span: {
            flexGrow: 1,
            display: "flex",
            justifyContent: "space-between"
          }
        }
      }}
    >
      <Stack
        styles={{ root: { width: "100%" } }}
        horizontal
        tokens={{ childrenGap: 16 }}
      >
        <span> {errorText}</span>
        {linkText && (to || onClick) && (
          <Stack.Item grow={0} shrink={0} styles={{ root: { fontSize: 12 } }}>
            <Navigate to={to} onClick={onClick}>
              {linkText}
            </Navigate>
          </Stack.Item>
        )}
      </Stack>
    </MessageBar>
  );
};
